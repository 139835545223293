import React from 'react';
import styles from '../../styles/home/home-banner.module.scss';
import scroll from "../../svg/scroll-down.svg";
import {StaticImage} from "gatsby-plugin-image";
import text from "../../svg/home-banner-text.svg";
import logo from "../../svg/home-banner-logo.svg";

const HomeBanner =  ({isMobile}) => {
    return (
        <div className="section">
            <div className={styles.container}>
                <a
                    href="/#beer"
                    className={styles.scrollContainer}
                >
                    <div className={styles.scrollTittle}>Scroll</div>
                    <img className={styles.scroll} src={scroll} alt="Scroll" />
                </a>
                <div className={styles.bannerContainer}>
                    <StaticImage
                        src="../../images/home-banner-1.webp"
                        height={1080}
                        quality={100}
                        formats={["AUTO"]}
                        alt="Lagers"
                        placeholder="none"
                        objectFit="cover"
                        objectPosition={"50% 0%"}
                        className={styles.firstImage}
                    />
                    <div className={styles.secondColumn}>
                        <StaticImage
                            src="../../images/home-banner-2.webp"
                            height={1080}
                            quality={100}
                            formats={["AUTO"]}
                            alt="Lagers"
                            placeholder="none"
                            objectFit="cover"

                            className={styles.secondImage}
                        />
                    </div>
                    <img src={text} alt="text" className={styles.text}/>
                    <img src={logo} alt="logo" className={styles.logo}/>
                </div>
            </div>
        </div>
    );
}

export default HomeBanner;
