import React, { useEffect, useState, useRef } from "react"
import { navigate } from "gatsby"
import isMobileDevice from "../utils/isMobileDevice"
import SEO from "../components/seo"
import HomeHero from "../components/home/home-hero"
import HomeBeer from "../components/home/home-beer"
import HomeHistory from "../components/home/home-history"
import HomeMerch from "../components/home/home-merch"
import Menu from "../components/shared/menu"
import Footer from "../components/shared/footer"
import gooseHead from "../svg/goose-head-new.svg"
import hamburger from "../svg/hamburger-white-and-green.svg"
import activeSlideIcon from "../svg/pagination-white.svg"
import inactiveSlideIcon from "../svg/pagination-gray.svg"
import styles from "../styles/home/home.module.scss"

// @ts-ignore
import ReactFullpage from "@fullpage/react-fullpage"
import { window } from "browser-monads"
import PopUp from "../components/pop-up/pop-up";
import HomeBanner from "../components/home/home-banner";

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(false)
  const [scrollCounter, setScrollCounter] = useState(0)
  const [showGooseHead, setShowGooseHead] = useState(false)
  const [showMenu, setShowMenu] = useState(false)

  const reactFullPageRef = useRef({ scrollCounter: 0, isMobile: false })

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!session && !local) navigate("/age-gate", { state: { newPath: "/" } })
  }, [])

  useEffect(() => {
    window.dataLayer.push({
      brand: "Goose Island",
      Zone: "SAZ",
      country: "ARG",
      city: "CABA",
      campaign: `${
        window.location.search.includes("?cmp=")
          ? window.location.search.split("?cmp=")[1].split("&")[0]
          : ""
      }`,
      cms: "0",
      event: "pageView",
      language: "es",
      login: false,
      pageName: "Home",
      pageType: "Basic page",
      SiteType: "Brand",
      product: "NA",
      sku: "NA",
      userUid: "",
      url: "https://www.gooseisland.com.ar/",
      url_campaign: `${
        window.location.search.includes("utm_campaign=")
          ? window.location.search.split("utm_campaign=")[1]
          : ""
      }`,
      Step_number: "",
    })
  }, [])

  useEffect(() => {
    const isMobileValue = isMobileDevice()
    setIsMobile(isMobileValue)
    reactFullPageRef.current.isMobile = isMobileValue
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    )
  }, [])

  const beforeLeave = (origin, destination, direction, trigger) => {
    if (destination.anchor === "history" && direction === "down") {
      if (reactFullPageRef.current.isMobile) {
        if (reactFullPageRef.current.scrollCounter !== 4) {
          reactFullPageRef.current.scrollCounter++
          setScrollCounter(reactFullPageRef.current.scrollCounter)
          return false
        }
      } else {
        if (reactFullPageRef.current.scrollCounter !== 2) {
          reactFullPageRef.current.scrollCounter++
          setScrollCounter(reactFullPageRef.current.scrollCounter)
          return false
        }
      }
    }
    if (destination.anchor === "hero" && direction === "up") {
      if (reactFullPageRef.current.scrollCounter > 0) {
        reactFullPageRef.current.scrollCounter--
        setScrollCounter(reactFullPageRef.current.scrollCounter)
        return false
      }
    }
    if (destination.anchor === "hero" && direction === "up") {
      if (reactFullPageRef.current.scrollCounter !== 0) {
        reactFullPageRef.current.scrollCounter = 0
        setTimeout(() => {
          setScrollCounter(0)
        }, "1000")
      }
    }
    return true
  }

  const onLeave = (origin, destination, direction) => {
    if (isMobile) {
      if (destination.anchor === "hero" || destination.anchor === "footer") {
        setTimeout(function () {
          setShowGooseHead(false)
        }, 200)
      }
      if (destination.anchor !== "hero" && destination.anchor !== "footer") {
        setTimeout(function () {
          setShowGooseHead(true)
        }, 200)
      }
    } else {
      if (destination.anchor === "hero") {
        setTimeout(function () {
          setShowGooseHead(false)
        }, 200)
      }
      if (destination.anchor !== "hero") {
        setTimeout(function () {
          setShowGooseHead(true)
        }, 200)
      }
    }
  }

  console.log("showGooseHead", showGooseHead);

  return (
    <div className={styles.homeContainer}>
      <SEO title="La cerveza de los gansos llegó a la Argentina" />
      <Menu
        isMobile={isMobile}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        reactFullPageRef={reactFullPageRef}
        setScrollCounter={setScrollCounter}
      />
      <PopUp/>
      <img
        className={`${styles.homeFloatingGooseHead} ${
          !showGooseHead && !showMenu && styles.homeFloatingDisabledGooseHead
        }`}
        src={gooseHead}
        alt="Goose"
      />
      <img
        className={styles.homeHamburger}
        src={hamburger}
        alt="Menu"
        onClick={() => setShowMenu(true)}
      />
      <div className={styles.homePaginationContainer}>
        <img
          className={styles.homePaginationIcon}
          src={
            window?.location.hash === "#hero" || window?.location.hash === ""
              ? activeSlideIcon
              : inactiveSlideIcon
          }
          alt="Slide"
        />
        <img
          className={styles.homePaginationIcon}
          src={
            window?.location.hash === "#beer"
              ? activeSlideIcon
              : inactiveSlideIcon
          }
          alt="Slide"
        />
        <img
          className={styles.homePaginationIcon}
          src={
            window?.location.hash === "#history"
              ? activeSlideIcon
              : inactiveSlideIcon
          }
          alt="Slide"
        />

        <img
          className={styles.homePaginationIcon}
          src={
            window?.location.hash === "#footer"
              ? activeSlideIcon
              : inactiveSlideIcon
          }
          alt="Slide"
        />
      </div>
      <ReactFullpage
        licenseKey={"OPEN-SOURCE-GPLV3-LICENSE"}
        scrollingSpeed={1000}
        css3={false}
        anchors={["hero", "beer", "history", "footer"]}
        onLeave={onLeave}
        beforeLeave={beforeLeave}
        touchSensitivity={10}
        credits={{ enabled: false }}
        render={({ state, fullpageApi }) => {
          return (
            <ReactFullpage.Wrapper>
              <HomeBanner isMobile={isMobile} />
              <HomeBeer
                isMobile={isMobile}
                scrollCounter={scrollCounter}
                setScrollCounter={setScrollCounter}
                reactFullPageRef={reactFullPageRef}
              />
              <HomeHistory isMobile={isMobile} />
              {/*<HomeMerch isMobile={isMobile} />*/}
              <Footer isMobile={isMobile} indexPage={true} />
            </ReactFullpage.Wrapper>
          )
        }}
      />
    </div>
  )
}

export default IndexPage
