import styles from '../../styles/pop-up/popup.module.scss'
import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import closeIcon from '../../images/close.svg'
import {Link} from "gatsby"


const PopUp = () => {
    const [showPopUp, setShowPopUp] = React.useState(true)
    const [showCloseIcon, setShowCloseIcon] = React.useState(false)

    const handleClose = () => {
        setShowPopUp(false)
    }


    return showPopUp && (
        <div className={styles.container} onClick={handleClose}>
            <div className={styles.imageContainer} onClick={() => {
                window.dataLayer.push({
                    "event": "GAEvent",
                    "event_category": "Content",
                    "event_action": "Boton",
                    "event_label": "TaDa",
                    "interaction": "True",
                    "component_name": "boton_tada",
                    "element_text": "TaDa",
                })
            }}>
                <Link
                    to={'https://link.tada.com.ar/3qSL/Egoose'}
                    target={'_blank'}

                >
                    <StaticImage
                        src={"../../images/popup.jpg"}
                        quality={100}
                        formats={["AUTO"]}
                        alt="Pop Up"
                        placeholder="none"
                        objectFit="cover"
                        className={styles.popUpImage}
                        onLoad={() => {
                            setShowCloseIcon(true)
                        }}

                    />
                </Link>

                {showCloseIcon && <img
                    src={closeIcon}
                    alt={'cerrar'}
                    className={styles.closeIcon}
                    onClick={handleClose}
                />}
            </div>
        </div>
    )
}

export default PopUp;
